import React from "react"
import Layout from "../../components/layout"
import {Jumbotron} from 'react-bootstrap'

const dis = (val) =>  
{ 
    document.getElementById("result").value+=val 
} 
  
//function that evaluates the digit and return result 
const solve = () => 
{ 
    let x = document.getElementById("result").value 
    let y = eval(x) 
    document.getElementById("result").value = y 
} 
  
//function that clear the display 
const clr = () => 
{ 
    document.getElementById("result").value = "" 
} 

const onlyNumberKey = (evt) => {          
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode 
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) 
    return false; 
    return true; 
}

export default () => (
  <Layout title="Contest | Random Quotes">
    <Jumbotron className="bg-lightblue">
        <div className="container">
        <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
            <div className="randomQuoteOuter">
                <table> 
                <tr> 
                <td colspan="3"><input type="text" placeholder="Enter here...." id="result" onkeypress={() => onlyNumberKey()} 
                        /></td> 
                <td><input type="button" value="c" onClick={() => clr()}/> </td> 
                </tr> 
                <tr> 
                <td><input type="button" value="1" onClick={() => dis('1')} /> </td> 
                <td><input type="button" value="2" onClick={() => dis('2')} /> </td> 
                <td><input type="button" value="3" onClick={() => dis('3')} /> </td> 
                <td><input type="button" value="/" onClick={() => dis('/')} /> </td> 
                </tr> 
                <tr> 
                <td><input type="button" value="4" onClick={() => dis('4')} /> </td> 
                <td><input type="button" value="5" onClick={() => dis('5')} /> </td> 
                <td><input type="button" value="6" onClick={() => dis('6')} /> </td> 
                <td><input type="button" value="-" onClick={() => dis('-')} /> </td> 
                </tr> 
                <tr> 
                <td><input type="button" value="7" onClick={() => dis('7')} /> </td> 
                <td><input type="button" value="8" onClick={() => dis('8')} /> </td> 
                <td><input type="button" value="9" onClick={() => dis('9')} /> </td> 
                <td><input type="button" value="+" onClick={() => dis('+')} /> </td> 
                </tr> 
                <tr> 
                <td><input type="button" value="." onClick={() => dis('.')} /> </td> 
                <td><input type="button" value="0" onClick={() => dis('0')} /> </td> 
                <td><input type="button" value="=" onClick={() => solve()} /> </td> 
                <td><input type="button" value="*" onClick={() => dis('*')}/> </td> 
                </tr> 
                </table> 
           </div>
        </div>
        </div>
    </Jumbotron>
  </Layout>
)